import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

let dummyText = `I build software solutions`;


let timer;
let restart;
export default function TextGenerator() {
  const [text, setText] = useState("");
  const [place, setPlace] = useState(0);
  const [renderText, setRenderText] = useState("");

  const allTexts = [
    'software solutions',
    'web applications',
    'API services',
  ];

  useEffect(() => {
    handleGenerate(0);

  }, []);

  const handleGenerate = (index) => {

    restart = setTimeout(() => {
      setPlace(place + 1)
    }, 2500)

    const tempText = allTexts[index % (allTexts.length)];

    let i = -1;
    timer = setInterval(() => {
      i++;
      if (i < tempText.length) setText((prev) => prev + tempText[i]);
    }, 20);
  };

  useEffect(() => {
    clearInterval(timer);
    setText("");
    handleGenerate(place);
  }, [place]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="inline">
      <div className="inline">
        {!text ? "" : text}
      </div>
    </div>
  );
}
