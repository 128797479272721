

import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

import './App.css';
import profile from './profile1.webp';
import desert from './desert.webp';

import SocialMedia from './components/SocialMedia';
import pdf from './Resume.pdf';

import TextGenerator from './TextGenerator';

const HeroSection = () => {
  const [scale, setScale] = useState(1);
  const [opacity, setOpacity] = useState(1);
  const [hide, setHide] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const scaleValue = Math.min(1 + scrollY / 1000, 2);
    const opacityValue = Math.max(1 - scrollY / 500, 0);

    setHide(scrollY > (window.innerHeight*1.5));
    setScale(scaleValue);
    setOpacity(opacityValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const HeroButton = ({ text }) => {
    return (
      <button className="mb-8 mr-4">
        <Link to={text} smooth={true} duration={500}
          className='
          hover:bg-orange-300 hover:text-stone-900 hover:py-3 border-r-8 border-b-8 border-stone-900 
          rounded-lg p-2
          text-xl
          text-stone-700
          bg-orange-200'>
          {text}
        </Link>
      </button>
    )
  }

  return (
    <div className="container min-h-screen max-w-screen-lg bg-none md:bg-none md:bg-none">

      <div className={`z-10 hidden fixed max-w-screen-lg w-full bottom-[15vh] h-[70vh] bg-stone-800 ${hide ? 'hidden' : 'md:block'}
                      lg:border-r-8 border-b-8 border-orange-300 mr rounded-lg overflow-hidden box-border text-left opacity-100`}
        style={{ transform: `scale(${scale})` }}></div>

      <div className={`z-5 fixed bg-orange-300 w-screen h-screen left-0 ${hide ? 'hidden' : 'md:block'}`} style={{ opacity }}>
        <img src={desert} className="absolute bottom-0 w-full h-[20%] object-cover" alt="Desert" />
        <div className="absolute bottom-0 w-full h-[20%] bg-amber-800 opacity-30"></div>

        <div className="absolute bottom-[20%] w-full h-[20%] opacity-90 bg-indigo-950"></div>
        <div className="absolute bottom-[40%] w-full h-[20%] opacity-60 bg-orange-400"></div>
        <div className="absolute bottom-[60%] w-full h-[28%] opacity-100 bg-slate-800"></div>
        <div className="absolute bottom-[88%] w-full h-[15%] opacity-100 bg-slate-900"></div>

        <div className="absolute bottom-[25%] w-full h-[6%] opacity-80 bg-orange-300"></div>
        <div className="absolute bottom-[50%] w-full h-[2%] opacity-60 bg-indigo-900"></div>
        <div className="absolute bottom-[70%] w-full h-[4%] opacity-60 bg-slate-900"></div>

      </div>

      <div className={`z-20 fixed w-full max-w-screen-lg flex h-screen justify-center items-center border-box text-left overflow-hidden ${opacity > 0 ? '' : 'hidden'}`}
        style={{ opacity: opacity }}>
        {/* box container */}

        <div className="flex flex-col-reverse md:flex-row">
          {/* Left Side */}
          <div className="bg-stone-800 border-solid p-4
                          md:bg-transparent md:border-none rounded-xl
                          md:w-1/4 flex flex-row-reverse md:flex-col justify-end md:justify-center items-center">
            <img src={profile} className="
                    mx-8
                    md:mx-0
                    h-48
                    md:h-auto
                    md:w-48
                    bg-neutral-300
                    rounded-lg                    
                    border-r-4
                    border-b-4
                    border-solid
                    border-orange-200"
              alt="Logo" />
            <div className="w-12 md:w-44 lg:w-48">
              <SocialMedia />
            </div>
          </div>

          {/* Right Side */}
          <div className='bg-stone-800 border-solid p-4
                          md:border-none rounded-xl
                          w-full mb-4 md:my-16 md:w-3/4 text-left md:pl-12'>
            <div className='md:pl-4 border-box'>
              <div className='md:mt-4 md:mb-8'>
                <h1 className="text-base inline">Hi, my name is </h1>
                <br></br>
                <h1 className="text-4xl font-bold inline">Miguel Gutierrez</h1>
                <h1 className="text-4xl font-bold text-blue-300 h-20">I build <TextGenerator />
                  .
                </h1>
              </div>

              <ul className='flex flex-inline items-center mb-8 flex-wrap mt-8'>
                <HeroButton text="About Me" />
                <HeroButton text="Work Experience" />
                <HeroButton text="Projects" />
                <button className="mb-8 mr-4">
                  <a href={pdf} target="_blank" rel="noopener noreferrer"
                    className='hover:bg-orange-700 border-r-8 border-b-8 border-stone-950 
                      hover:py-3
                      rounded-lg p-2
                      text-xl
                      text-stone-900 
                      bg-blue-300'>
                    Resume
                  </a>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
